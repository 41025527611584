import React from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { useReactiveVar, useMutation } from '@apollo/client'
import Badge from 'react-bootstrap/Badge'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/pro-solid-svg-icons'
import { user } from '@cache'
import User from '@utils/storage/user'
import toast from '@utils/toast'
import LOGOUT from '@graphql/mutations/logout'
import { t } from '@utils/translations'

function Navigation() {
  const currentUser = useReactiveVar(user)
  const navigate = useNavigate()

  const [logout] = useMutation(LOGOUT, {
    onCompleted(data) {
      const message = data?.logout?.message || t('messages.loggedOut')
      User.clear()
      navigate('/')
      toast(message)
    },
    onError(error) {
      const message = error?.message || t('messages.logoutError')
      toast(message, 'danger')
    }
  })

  const handleLogout = () => {
    logout()
  }

  if (!currentUser) return null

  return (
    <Navbar expand='lg' bg='dark' variant='dark'>
      <Container fluid='xl'>
        <Navbar.Brand as={Link} to='/'>BSP Processor</Navbar.Brand>
        <Navbar.Toggle aria-controls='responsive-navbar-nav' />
        <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav as='ul' className='me-auto'>
            <Nav.Item as='li'><NavLink to='/transactions' className='nav-link'>Transactions</NavLink></Nav.Item>
            <Nav.Item as='li'><NavLink to='/merchants' className='nav-link'>Merchants</NavLink></Nav.Item>
            <Nav.Item as='li'><NavLink to='/flights' className='nav-link'>Flights</NavLink></Nav.Item>
            <Nav.Item as='li'><NavLink to='/invoices' className='nav-link'>Invoices</NavLink></Nav.Item>
            <Nav.Item as='li'><NavLink to='/miscellaneous-details' className='nav-link'>Details</NavLink></Nav.Item>
            <Nav.Item as='li'><NavLink to='/miscellaneous-taxes' className='nav-link'>Taxes</NavLink></Nav.Item>
            <Nav.Item as='li'><NavLink to='/payment-plans' className='nav-link'>Payment Plans</NavLink></Nav.Item>
            <Nav.Item as='li'><NavLink to='/transaction-batches' className='nav-link'>Transaction Batches</NavLink></Nav.Item>
          </Nav>
          <Navbar.Text>
            <Button variant='link' onClick={handleLogout}>
              <FontAwesomeIcon icon={faLock} className='me-1' />
              Logout <Badge bg='primary'>{currentUser.login}</Badge>
            </Button>
          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default Navigation
