import { gql } from '@apollo/client'

/*
 * This mutation is responsible for triggerring logout
 * from the BSP Processor.
 */

const LOGOUT = gql`
  mutation Logout {
    logout {
      success
      message
    }
  }
`

export default LOGOUT
